<template>
    <div>
        <Preloader v-if='loader' />

        <h4 class='mb-4 page-title'>Создать платеж</h4>
        <div>
            <b-form class='g-1 p-4' @submit.prevent='submitForm' @reset.prevent='resetForm'>
                <div class='widget row'>
                    <b-form-group label='Цена: *' class='col-6' label-for='input-1'>
                        <b-form-input
                            id='input-1'
                            type='number'
                            placeholder='Цена'
                            required
                            v-model.number='form.amount'
                        />
                    </b-form-group>

                    <b-form-group label='Начальная цена: *' class='col-6' label-for='input-2'>
                        <b-form-input
                            id='input-2'
                            type='number'
                            placeholder='Начальная цена'
                            v-model.number='form.origin_amount'
                            required
                        />
                    </b-form-group>

                    <b-form-group label='Валюта: *' class='col-6' label-for='input-3'>
                        <b-form-select v-if='currency_list' id='input-3' :options='currency_list'
                                       v-model='form.currency' required></b-form-select>
                    </b-form-group>

                    <b-form-group label='Статус: *' class='col-6' label-for='input-4'>
                        <b-form-select v-if='statuses' id='input-4' :options='statuses' v-model='form.status'
                                       required></b-form-select>
                    </b-form-group>

                    <div class='col-12 mb-2'>Студент:</div>
                    <div v-if='students' class='radio-select-wrap col-6'>
                        <b-form-radio v-model='studentType' name='radio' value='student_id' />
                        <Multiselect
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            label='name'
                            track-by='id'
                            placeholder='Выберите студента'
                            :show-no-results='true'
                            :searchable='true'
                            :loading='studentsMultiselectLoading'
                            :internal-search='false'
                            :disabled="!selectedStudentType('student_id')"
                            :options='students.data'
                            v-model='student'
                            @search-change='searchStudent'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='students.meta.current_page'
                                    :last-page='students.meta.last_page'
                                    :callback='fetchStudents'
                                />
                            </template>

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }} - {{ props.option.parent_phone }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }} - {{ props.option.parent_phone }}</span>
                                </div>
                            </template>

                            <span slot='noResult'>Нет студентов с таким именем</span>
                            <span slot='noOptions'>Список студентов загружается...</span>
                        </Multiselect>
                    </div>

                    <div class='radio-select-wrap col-6'>
                        <b-form-radio v-model='studentType' name='radio' value='info' />
                        <b-form-input class='mb-2' v-model='form.name' type='text' placeholder='Имя'
                                      :disabled="!selectedStudentType('info')" />

                        <b-form-input v-model='form.phone' type='text' placeholder='Телефон'
                                      :disabled="!selectedStudentType('info')" />
                    </div>

                    <div class='col-12 mb-2'>Продукт:</div>
                    <div v-if='products' class='radio-select-wrap col-6'>
                        <b-form-radio v-model='productType' name='productType' value='subscription' />

                        <Multiselect
                            id='input-6'
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            :tagable='true'
                            label='title'
                            track-by='id'
                            placeholder='Выберите Абонемент'
                            :show-no-results='true'
                            :searchable='true'
                            :loading='productsMultiselectLoading'
                            :internal-search='false'
                            :disabled="!selectedProductType('subscription')"
                            :options='products.data'
                            v-model='product'
                            @search-change='searchProduct'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='products.meta.current_page'
                                    :last-page='products.meta.last_page'
                                    :callback='fetchProducts'
                                />
                            </template>

                            <span slot='noResult'>Нет абонементов с таким именем</span>
                            <span slot='noOptions'>Список абонементов загружается...</span>
                        </Multiselect>
                    </div>

                    <div class='radio-select-wrap col-6'>
                        <b-form-radio v-model='productType' name='productType' value='info' />
                        <b-form-input v-model='form.product_name' type='text' placeholder='Название продукта'
                                      :disabled="!selectedProductType('info')" />
                    </div>

                    <div class='col-12 mb-2'>Промокод:</div>
                    <div v-if='promoCodes' class='radio-select-wrap col-6'>
                        <Multiselect
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            label='name'
                            track-by='id'
                            placeholder='Выберите промокод'
                            :show-no-results='true'
                            :searchable='true'
                            :loading='promoCodesMultiselectLoading'
                            :internal-search='false'
                            :custom-label='promoCodeCustomLabel'
                            :options='promoCodes.data'
                            v-model='promoCode'
                            @search-change='searchPromoCodes'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='promoCodes.meta.current_page'
                                    :last-page='promoCodes.meta.last_page'
                                    :callback='fetchPromoCodes'
                                />
                            </template>

                            <span slot='noResult'>Нет промокода с таким именем</span>
                            <span slot='noOptions'>Список промокодов загружается...</span>
                        </Multiselect>
                    </div>

                    <b-form-group label='Комментарий:' class='col-12' label-for='input-10'>
                        <b-form-textarea id='input-10' v-model='form.comment' type='text' placeholder='Комментарий' />
                    </b-form-group>
                </div>

                <b-form-group class='row justify-content-end'>
                    <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
                    <b-button type='reset' variant='primary'>Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import MultiselectAfterList from '@/components/admin/MultiselectAfterList';
import { debounce } from 'debounce';

export default {
    name: 'TransactionCreate',
    components: {
        MultiselectAfterList,
        Preloader: () => import('@/components/Preloader'),
        Multiselect: () => import('vue-multiselect'),
    },
    data() {
        return {
            form: {
                amount: null,
                origin_amount: null,
                currency: null,
                status: null,
                student_id: null,
                product_name: null,
                product_id: null,
                name: null,
                phone: null,
                comment: null,
                promo_code_id: null,
            },
            studentsMultiselectLoading: false,
            promoCodesMultiselectLoading: false,
            productsMultiselectLoading: false,
            loader: false,
            currency_list: null,
            statuses: null,
            promoCodes: null,
            promoCode: null,
            status: null,
            students: null,
            student: null,
            products: null,
            product: null,
            studentType: 'student_id', // student_id or info
            productType: 'subscription', // subscription || info || certificate || tournament
        };
    },
    computed: {
        selectedStudentType() {
            return (type) => this.studentType === type;
        },
        selectedProductType() {
            return (type) => this.productType === type;
        },
    },
    methods: {
        async submitForm() {
            try {
                this.formValidation();
                this.formPreparation();
                const { data } = await this.$axios.post('crm/transactions', this.form);
                await this.$router.push({ name: 'TransactionEdit', params: { id: data.id } });
            } catch (ex) {
                console.log('cant create transaction: ' + ex);
                this.$toasted.error(ex, { position: 'bottom-right' });
            }
        },
        formValidation() {
            if (!this.student && !(this.form.name && this.form.phone)) {
                throw new Error('Выберите студента');
            } else if (!this.product && !this.form.product_name) {
                throw new Error('Выберите продукт');
            }
        },
        formPreparation() {
            if (this.selectedStudentType('student_id') && this.student) {
                this.$set(this.form, 'student_id', this.student.id);
            }

            if (this.promoCode) {
                this.$set(this.form, 'promo_code_id', this.promoCode.id);
            }

            if (this.selectedProductType('subscription') && this.product) {
                this.$set(this.form, 'product_id', this.product.id);
            }

            if (this.form.amount) {
                this.$set(this.form, 'amount', +this.form.amount);
            }

            if (this.form.amount_origin) {
                this.$set(this.form, 'amount_origin', +this.form.amount_origin);
            }
        },
        resetForm() {
            this.$router.back();
        },
        async fetchCurrencies() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-currencies');
                this.currency_list = data;
            } catch (ex) {
                console.log('cant fetch currency: ' + ex);
            }
        },
        async fetchStatuses() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-statuses');
                this.statuses = data;
            } catch (ex) {
                console.log('cant fetch statuses: ' + ex);
            }
        },
        async fetchStudents(query = null) {
            try {
                const { data } = await this.$axios.get('crm/students/list', { params: query });

                if (!this.students) {
                    this.students = data;
                } else {
                    this.$set(this.students, 'data', [...this.students.data, ...data.data]);
                    this.$set(this.students, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch students: ' + ex);
            }
        },
        searchStudent: debounce(async function(query) {
            this.studentsMultiselectLoading = true;
            this.findStudent({ query })
                .then(response => {
                    this.students = response;
                    this.studentsMultiselectLoading = false;
                })
                .catch(ex => {
                    this.studentsMultiselectLoading = false;
                    console.log('cant find students: ' + ex);
                });
        }, 1000),
        async findStudent(query = null) {
            try {
                const { data } = await this.$axios.get('crm/students/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find student: ' + ex);
            }
        },
        async fetchPromoCodes(query = null) {
            try {
                const { data } = await this.$axios.get('crm/promo-codes/list', { params: query });

                if (!this.promoCodes) {
                    this.promoCodes = data;
                } else {
                    this.$set(this.promoCodes, 'data', [...this.promoCodes.data, ...data.data]);
                    this.$set(this.promoCodes, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch promo codes: ' + ex);
            }
        },
        searchPromoCodes: debounce(async function(query) {
            this.promoCodesMultiselectLoading = true;
            this.findPromoCodes({ query })
                .then(response => {
                    this.promoCodes = response;
                    this.promoCodesMultiselectLoading = false;
                })
                .catch(ex => {
                    this.promoCodesMultiselectLoading = false;
                    console.log('cant find promo codes: ' + ex);
                });
        }, 1000),
        async findPromoCodes(query = null) {
            try {
                const { data } = await this.$axios.get('crm/promo-codes/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find promo code: ' + ex);
            }
        },
        async fetchProducts(query = null) {
            try {
                const { data } = await this.$axios.get('crm/products/list', { params: query });

                if (!this.products) {
                    this.products = data;
                } else {
                    this.$set(this.products, 'data', [...this.products.data, ...data.data]);
                    this.$set(this.products, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch products: ' + ex);
            }
        },
        searchProduct: debounce(async function(query) {
            this.productsMultiselectLoading = true;
            this.findProduct({ query })
                .then(response => {
                    this.products = response;
                    this.productsMultiselectLoading = false;
                })
                .catch(ex => {
                    this.productsMultiselectLoading = false;
                    console.log('cant find products: ' + ex);
                });
        }, 1000),
        async findProduct(query = null) {
            try {
                const { data } = await this.$axios.get('crm/products/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find products: ' + ex);
            }
        },
        promoCodeCustomLabel({ title, code }) {
            return `${title} - ${code}`;
        },
    },
    async created() {
        this.loader = true;
        await this.fetchCurrencies();
        await this.fetchStatuses();
        await this.fetchStudents();
        await this.fetchProducts();
        await this.fetchPromoCodes();
        this.loader = false;
    },
};
</script>

<style lang='scss' scoped></style>