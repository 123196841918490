<template>
    <div class="multiselect-after-list">
        <div v-if="hasNextPage" v-observe-visibility="reachedEnd" />
    </div>
</template>

<script>
export default {
    name: 'MultiselectAfterList',
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
        lastPage: {
            type: Number,
            required: true,
        },
        callback: {
            type: Function,
            required: true,
        },
    },
    computed: {
        hasNextPage() {
            return this.currentPage < this.lastPage;
        }
    },
    methods: {
        async reachedEnd(reached) {
            if (reached && this.hasNextPage) {
                this.callback({ page: this.currentPage + 1 });
            }
        },
    },
};
</script>

<style scoped></style>